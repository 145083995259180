<template>
  <div class="app-container">
  <el-row :gutter="4" type="flex" justify="center">
    <el-col :span="20">
      <el-card class="box-card">
        <div slot="header" class="clearfix" justify="center" align="center">
          <strong><span>Nuevo Proyecto</span></strong>
        </div>
        <div class="text item">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <el-form label-position="top" class="size-form-item" label-width="120px" :model="form">
              <el-row justify="space-around" align="middle">
                <el-col :sm="24" :md="24" :lg="24" align="middle">
                  <el-form-item label="Imagen de Proyecto">
                    <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="form.image"
                      @getImage="getSelectedImage"
                      ></ImageUpload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around">
                <el-col :sm="24" :md="24" :lg="24" align="left">
                  <el-form-item label="Default para donaciones">
                    <el-switch
                    :disabled="existDefault"
                      v-on:change="changeDefault()"
                      v-model="form.isDefault"
                      active-text="Si"
                      inactive-text="No">
                    </el-switch>
                  </el-form-item>
                  <el-form-item label="Estado del Proyecto">
                    <el-switch
                      :disabled="enableStatus"
                      v-model="form.isActive"
                      active-text="Activo"
                      inactive-text="Inactivo">
                    </el-switch>
                  </el-form-item>
                  <el-form-item label="Mostrar en aplicación">
                    <el-switch
                      :disabled="enableShow"
                      v-model="form.showInApp"
                      active-text="Si"
                      inactive-text="No">
                    </el-switch>
                  </el-form-item>
                  <el-form-item label="Título">
                    <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.title"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Nombre de clave">
                    <ValidationProvider name="Nombre de clave" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.keyName" @input="reformatKey"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Sección">
                    <ValidationProvider name="Sección" rules="required" v-slot="{ errors }">
                      <el-select size="mini" v-model="form.section" >
                        <el-option
                          v-for="item in sections"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Descripción">
                    <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                      <el-input type="textarea" :rows="4" v-model="form.description"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row  justify="center" >
                <el-col align="middle">
                  <el-form-item label="Contenido" style="width: 70% !important;">
                    <ValidationProvider name="Contenido" rules="required" v-slot="{ errors }">
                      <CKEditor :setContent="form.content" :key="form.content" @getContent="getUpdatedContent"></CKEditor>
                      <span style="color: #F56C6C;">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row  justify="center" >
                <el-col align="middle">
                  <template>
                    <el-carousel :interval="0" arrow="always" type="card" @change="handleCarouselChange">
                      <el-carousel-item v-for="(image, index) in form.carousel" :key="index">
                        <div class="image-card" style="max-width: 100% !important; height: auto !important;">
                          <img :src= "`${host + image}`" alt="imagen alt" style="max-width: 100% !important; height: auto !important;"/>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </template>
                </el-col>
              </el-row>
              <el-row  justify="center" style="margin-bottom: 4rem;">
                <el-col align="middle">
                  <div style="margin-top: 1.2rem;">
                      <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="newImage"
                      @getImage="getSelectedImageCarousel"
                      ></ImageUpload>
                    </div>
                    <div style="margin-top: 1.2rem;">
                      <el-button :disabled="addImageToCarouselButtonDisabled || newImage == null" @click="addImageToCarousel" type="primary">Agregar a carrousel</el-button>
                      <el-button :disabled="form.carousel.length == 0" @click="removeImageFromCarousel" type="danger">Elimina de carrousel</el-button>
                    </div>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row type="flex" justify="center">
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCreate"
                      type="primary"
                      icon="el-icon-upload2"
                      :disabled="invalid">
                      Agregar
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCancel"
                      type="danger">
                      Cancelar
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </ValidationObserver>
        </div>
      </el-card>
    </el-col>
  </el-row>
  </div>
</template>

<script>
import CKEditor from '@/components/CKEditor/CKEditor.vue'
import { uploadImageFile } from '@/api/image.js'
import { search } from '@/api/gallery.js'
import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import { createProject, existDefault } from '@/api/projects.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi

export default {
  name: 'createProject',
  components: {
    ImageUpload,
    CKEditor
  },
  data () {
    return {
      host: urlServer,
      form: {
        carousel: [],
        content: '',
        title: '',
        description: '',
        image: null,
        isActive: true,
        showInApp: true,
        isDefault: false,
        keyName: '',
        section: ''
      },
      contentEditor: '',
      loadingImage: false,
      imageSelected: false,
      addImageToCarouselButtonDisabled: false,
      newImage: null,
      currentIndexCarousel: 0,
      enableStatus: false,
      enableShow: false,
      existDefault: false,
      sections: [
        {
          label: 'Primera Sección',
          value: '1'
        },
        {
          label: 'Segunda Sección',
          value: '2'
        },
        {
          label: 'Tercera Sección',
          value: '3'
        },
        {
          label: 'Cuarta Sección',
          value: '4'
        }
      ]
    }
  },
  mounted () {
    this.handleExistDefault()
  },
  methods: {
    reformatKey () {
      this.form.keyName = this.form.keyName.replace(/\s/g, '_')
    },
    addImageToCarousel () {
      this.form.carousel.push(this.newImage)
      this.addImageToCarouselButtonDisabled = this.newImage == null
    },
    removeImageFromCarousel () {
      this.form.carousel.splice(this.currentIndexCarousel, 1)
      this.currentIndexCarousel = 0
    },
    getSelectedImageCarousel (imageUploaded) {
      this.newImage = imageUploaded
      this.addImageToCarouselButtonDisabled = this.newImage == null
    },
    handleCarouselChange (index) {
      // Puedes acceder a la imagen seleccionada en este punto
      this.currentIndexCarousel = index
      /* console.log('INDICE IMAGEN')
      console.log(this.form.carousel[index])
      console.log('CURRENTINDEX')
      console.log(this.currentIndexCarousel) */
    },
    async postImageTo (param) {
      return await uploadImageFile(param)
    },
    async getGallery (param) {
      return await search(param)
    },
    getSelectedImage (imageUploaded) {
      this.form.image = imageUploaded
      console.log('imagen cargada en formulario ' + this.form.image)
    },
    handlerCancel () {
      this.$router.back()
    },
    async handlerCreate (info) {
      this.form.content = this.contentEditor
      if (this.form.image !== '' && this.form.image !== null) {
        try {
          const response = await createProject(this.form)
          if (response.success) {
            console.log(response)
            this.$message({
              showClose: true,
              message: '¡Se creó correctamente!',
              type: 'success'
            })
            this.$router.push('/projects')
          } else {
            console.log(response)
          }
        } catch (err) {
          err.response.data.messages.forEach(message => {
            this.$message.error(`${message}`)
          })
        }
      } else {
        this.$message.error('Asegurate que no falte ningun archivo o dato')
      }
    },
    async handleExistDefault () {
      try {
        const response = await existDefault()
        if (response.success) {
          this.existDefault = response.data
        } else {
          console.log(response)
        }
      } catch (err) {
        err.response.data.messages.forEach(message => {
          this.$message.error(`${message}`)
        })
      }
    },
    changeDefault () {
      if (this.form.isDefault) {
        this.form.isActive = true
        this.form.showInApp = false
        this.enableStatus = true
        this.enableShow = true
      } else {
        this.enableStatus = false
        this.enableShow = false
      }
    },
    getUpdatedContent (content) {
      this.contentEditor = content
    }
  }
}
</script>

<style scoped>
.label-form-validate-wrong {
  color: #F56C6C;
}

.size-form-item > .el-form-item {
  margin: 0;
}
.size-form-item > .el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
}
.my-autocomplete {
  width: 500px;
}

.project-slide {
  text-align: center;
}

.project-slide img {
  max-width: 100%;
  max-height: 300px; /* Ajusta la altura según las necesidades */
}
</style>
